// iOS safariでの100vh対応
const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
 
window.addEventListener('load', setVh);
window.addEventListener('resize', setVh);


// rollover
var preLoadImg = new Object();
 
function initRollOvers(){
  $("img.imgover,input.imgover").each(function(){
    var imgSrc = this.src;
    var sep = imgSrc.lastIndexOf('.');
    var onSrc = imgSrc.substr(0, sep) + '_o' + imgSrc.substr(sep, 4);
    preLoadImg[imgSrc] = new Image();
    preLoadImg[imgSrc].src = onSrc;
    $(this).hover(
      function() { this.src = onSrc; },
      function() { this.src = imgSrc; }
    );
  });
}
$(function(){
  initRollOvers();
});


// smoothScroll
jQuery.easing.quart = function (x, t, b, c, d) {
  return -c * ((t=t/d-1)*t*t*t - 1) + b;
};

jQuery(document).ready(function(){
  jQuery('a[href*="#"]').click(function() {    
    var targetId = jQuery(this.hash);
    console.log(targetId);
    
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = jQuery(this.hash);
      target = target.length && target || jQuery('[name=' + this.hash.slice(1) +']');

      // ターゲット先へスクロール移動
      if (target.length) {
        var targetOffset = target.offset().top;
        jQuery('html,body').animate({ scrollTop: targetOffset }, 1200, 'quart');

        // ターゲット先がスライドパネルの場合はオープン
        if (target.next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
        } else {
          target.next(".js-slideBox").find(".js-slideBtn").addClass('is-active');
          target.next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
        }

        return false;
      }
    }
  });


});


// slide btn
$(function(){
  $(".js-slideBtn").each(function(index,element) {
    $(element).click(function() {
      $(this).toggleClass('is-active');
      $(element).next(".js-slidePanel").slideToggle(300);
    });
  });

  // ハッシュがあるときはオープン
  if (window.location.hash.length>0) {
    if ($(window.location.hash).next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
    } else {
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").toggleClass('is-active');
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
    }
  }
});


// current
$(function() {
  var gNavItem = $("#gnav .js-gnavItem");

  var local_path_arr = $(location).attr('pathname').split('/');
  var local_dir = local_path_arr[1];

  gNavItem.each(function(){
    var href_path = $(this).attr("href");
    var href_path_arr = href_path.split('/');
    var href_dir = href_path_arr[1];
    
    if ($(this).hasClass("js-externalSite") != true) {  //外部サイト(classにjs-externalSiteを設定)へのリンクは、current表示の対象外とする
      if(href_dir == local_dir){
        $(this).addClass('is-current');
      } else {
        $(this).removeClass('is-current');
      }
    }
  });

});


// header scroll settings
$(window).on('load scroll resize', function(){
  var dispHeaderPosTop = 0;  //固定表示ヘッダーの表示開始位置
  var winscrW = window.innerWidth;  //スクロールバーを含む幅

  if ($('#dispheader').length) {  //ページ内にヘッダー表示位置指定ID(id="dispheader")がある場合のみ、表示開始座標を設定する
    dispHeaderPosTop = $('#dispheader').offset().top;
  }

  var winW = $(window).width();
  var winscrW = window.innerWidth;  //スクロールバーを含む幅
  var hd = $('#header');
  var scroll = $(window).scrollTop();

  if ( winscrW >= 800 && scroll < dispHeaderPosTop ) {
    if (hd.hasClass('js-isscroll')) {
      hd.removeClass('js-isscroll');
      hd.addClass('js-noscroll');
    }
  } else {
    hd.removeClass('js-initscroll');
    hd.removeClass('js-noscroll');
    hd.addClass('js-isscroll');
  }
});


// バーガーメニュー開閉
$(function(){
  $("#js-drawerOpen").click(function() {
    if ($("#js-drawerOpen").hasClass('is-open')) {
      $("#drawer").addClass('is-clickClose');

      $("#js-drawerOpen").removeClass('is-open');
      $("#drawer").removeClass('is-open');
      $("#gnav").removeClass('is-open');
      $("#js-drawerOpen").addClass('is-close');
      $("#drawer").addClass('is-close');
      $("#gnav").addClass('is-close');
    } else {
      $("#drawer").removeClass('is-clickClose');
      $("#js-drawerOpen").removeClass('is-close');
      $("#drawer").removeClass('is-close');
      $("#gnav").removeClass('is-close');
      $("#js-drawerOpen").addClass('is-open');
      $("#drawer").addClass('is-open');
      $("#gnav").addClass('is-open');
    }
  });
});

// header resize settings(800px未満から800px以上へのリサイズ時に、ドロワーメニューを閉じる)
/*
$(window).on('resize', function(){
  var winscrW = window.innerWidth;  //スクロールバーを含む幅

  if (winscrW >= 800) {
    $("#drawer").removeClass('is-clickClose');

    if ($("#js-drawerOpen").hasClass('is-open')) {
      $("#js-drawerOpen").removeClass('is-open');
      $("#drawer").removeClass('is-open');
      $("#gnav").removeClass('is-open');
      $("#js-drawerOpen").addClass('is-close');
      $("#drawer").addClass('is-close');
      $("#gnav").addClass('is-close');
    }
  }
});
*/


//fadein
$(window).scroll(function (){
  $('.js-fadeIn').each(function(){
    var elemPos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();

    if (scroll > elemPos - windowHeight - 30){
        $(this).addClass('js-scrollIn');
    }
  });
});




//LP下部固定ナビは、KV以下までスクロールするとclassを変える(ロードリサイズ時・スクロール時それぞれの処理)
$(window).on('load resize', function() {
  var winW    = $(window).width();
  var value   = $(this).scrollTop();
  var fixNav = $('.p1-fixBtmNav');
  var lpbtmnav = $('.js-lpbtmnav');
  var dispPos = fixNav.outerHeight();

  if ( value > dispPos ) {
    lpbtmnav.removeClass("no-scroll");
    lpbtmnav.addClass("is-scroll");
  } else {
    lpbtmnav.removeClass("is-scroll");
  }
});

$(window).on('scroll', function() {
  var winW    = $(window).width();
  var value   = $(this).scrollTop();
  var fixNav = $('.p1-fixBtmNav');
  var lpbtmnav = $('.js-lpbtmnav');
  var dispPos = fixNav.outerHeight();

  if ( value > dispPos ) {
    lpbtmnav.removeClass("no-scroll");
    lpbtmnav.addClass("is-scroll");
  } else {
    lpbtmnav.removeClass("is-scroll");
    lpbtmnav.addClass("no-scroll");
  }
});


// 電話番号リンク
if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
  $(function() {
    $('.js-tellink').each(function() {
      var str = $(this).html();

      if ($(this).children().is('img')) {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      } else {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      }
    });
  });
}


/* 指定行数で以下略にする */
// 省略対象のclassを指定
var omit = document.getElementsByClassName('js-omit');

$(window).on('load resize', function() {
  //表示する行数を取得(HTMLの「data-omitline-max」属性に行数を数値で指定する)
  lineNum = $('.js-omit').data('omitline');

  // 元の文章をdata-origintextに退避
  omit_init();

  // 文章を省略する処理。
  omit_sentence();
});

/**
* 文字省略の初期設定
*/
function omit_init() {
  for (var i = 0; i < omit.length; i++) {
    if(omit[i].dataset.origintext === undefined) {
      var sentence = omit[i].textContent;
      omit[i].dataset.origintext = sentence;
    }
  }
}

/**
* 文字省略
* line - 表示する行数(ここで数値した行数以上は、省略する)
*/
function omit_sentence() {
  var line = new Array();
  var winscrW = window.innerWidth;  /* スクロールバーを含む幅 */

  var dispMode = "pc";

  if (winscrW <= 800) {
    dispMode = "sp";
  }

  // 対象の文章を1文字ずつspanで区切る
  for (var i = 0; i < omit.length; i++) {
    line[i] = omit[i].getAttribute('data-omitline-max');  //表示する行数

    if (omit[i].hasAttribute('data-omit-target')) {  //data-omit-target属性がある場合のみ、対象ごとに独自処理
      omitTarget = omit[i].getAttribute('data-omit-target');

      /*
       * トップページのお客様の声の文章用
       * 375px以下→1列なので変更なし、420px以下→2列で小さいので2行減らす、480px以下→2列で多少小さいので1行減らす
       */
      if (omitTarget == 'toppage-rev-txt') {
        if (winscrW <= 700) {
          line[i] = line[i] - 3;  //3行減らす
        } else if (winscrW <= 1000) {
          line[i] = line[i] - 2;  //2行減らす
        }
      }
    }
    
    omit[i].innerHTML = divide_sentence(omit[i].dataset.origintext);
  }

  // 区切った文字の行数(座標)を調べる
  for (var i = 0; i < omit.length; i++) {
    var s = omit[i].getElementsByTagName('span');
    // 省略する位置を調べる
    var omitIndex = check_character_row_count(s, line[i]);

    // 省略した文章を反映
    var sentence = omit[i].dataset.origintext;
    if(omitIndex !== undefined) {
      sentence = sentence.slice(0, omitIndex) + '…';
    }
    omit[i].textContent = sentence;
  }
}

/**
* 文章を1文字ずつspanで括って返す
* text - 分割する文字列
*/
function divide_sentence(text) {
  var textArr = text.split('');
  var divideText = '';
  for (var i = 0; i < textArr.length; i++) {
    divideText += '<span>' + textArr[i] + '</span>';
  }
  divideText += '<span>' + '…' + '</span>';
  return divideText;
}

/**
* 省略する位置を調べて返す
* @param {object} text - spanで区切った文字群
* @param {number} line - 省略する行数
*/
function check_character_row_count(text, line) {
  var lineCount = 0;
  var beforeTop = text[0].getBoundingClientRect().top - 1;
  var omitIndex = undefined;
  for (var i = 0; i < text.length - 1; i++) { // 文章の最後に確認用の省略文字が入っているので、最後の文字はチェックから除外
    var currentTop = text[i].getBoundingClientRect().top;
    // 今回の文字の座標が前回の文字の座標より先に進んでいる時
    if(currentTop > beforeTop) {
      beforeTop = currentTop;
      lineCount++;
    }
    // 調べている文字が省略行数を過ぎた時
    if(lineCount > line) {
      // 省略の記号を入れるため、文字の幅から切り取り位置の調整
      var ellipsisWidth = text[text.length - 1].getBoundingClientRect().width + 1;
      var c = 0;
      var w = 0;
      do {
        c++;
        w += text[i - c].getBoundingClientRect().width;
      } while(w < ellipsisWidth);
      omitIndex = i - c;
      break;
    }
  }
  return omitIndex;
}
